export const fr = {
    "app_name": "DiRIF",
    "home": "Tableau de bord",
    "back": "Retour",
    "cancel": "Annuler",
    "send": "Envoyer",
    "yes": "Oui",
    "no": "Non",
    "close": "Fermer",
    "created_at": "Créé le",
    "validate": "Valider",
    "submit": "Soumettre",
    "copy": "Copier",
    "paste": "Coller",
    "options": "Options",
    "create": "Créer",
    "add": "Ajouter",
    "update": "Modifier",
    "save": "Enregistrer",
    "refuse": "Refuser",
    "manage": "Gérer",
    "download_excel_full": "Télécharger le fichier complet",
    "download_excel": "Télécharger",
    "download_pdf": "Télécharger (PDF)",
    "error_download_synthese": "Erreur lors du téléchargement de la synthèse.",
    "archive": "Archiver",
    "save_draft": "Enregistrer comme brouillon",
    "validate_temp": "Valider",
    "validate_def": "Valider définitivement",
    "validate_send": "Valider et envoyer",
    "delete_confirm": "Êtes-vous sûr de vouloir supprimer {item} ?",
    "field_required": "Le champ {0} est requis",
    "error_min_date": "Le champ {0} dépasse la date minimale autorisée",
    "error_max_date": "Le champ {0} dépasse la date maximale autorisée",
    "bad_format": "Le format du champ {0} n'est pas correct",
    "min_max_length": "Le champ {0} doit faire entre {1} et {2} caractères",
    "min_length": "Le champ {0} doit faire au moins {1} caractère(s)",
    "max_length": "Le champ {0} ne doit pas faire plus de {1} caractère(s)",
    "min_max_value": "Le champ {0} doit être compris entre {1} et {2}",
    "min_value": "Le champ {0} doit être supérieur à {1}",
    "bad_type": "Le champ {0} doit être {1}",
    "type_integer": "un nombre entier",
    "login_not_found": "Utilisateur inconnu, merci de vérifier vos identifiants",
    "bad_pwd": "Merci de vérifier votre mot de passe",
    "unauthorized": "Non autorisé",
    "unknown_error": "Une erreur est survenue",
    "login_already_exists": "Le login/mail existe déjà",
    "error_affectation_needed": "Une affection est requise",
    "pwd_current": "Vérification de votre mot de passe actuel",
    "pwd_new1": "Nouveau mot de passe",
    "pwd_new2": "Répéter le nouveau mot de passe",
    "email_new1": "Nouvelle adresse mail",
    "email_new2": "Répéter la nouvelle adresse mail",
    "sign_in": "Se connecter",
    "interventions": "Interventions",
    "pwd_forget": "Mot de passe oublié ?",
    "goto_table": "Voir le tableau",
    "confirm_archive": "Êtes-vous sûr de vouloir archiver l'ensemble des données de l'année en cours ?",
    "archive_dialog": "Archivage des données",
    "archived": "Les données de l'année ont été archivées",
    "undefined": "Non défini",
    "undefined_short": "ND",
    "choose_file": "Choisir",
    "add_file": "Ajouter",
    "import_file": "Importer",
    "import": "Importer",
    "cancel_file": "Annuler",
    "drag_files": "Faite glisser vos fichiers",
    "details": "Détails",
    "pwd_forget_enter_mail": "Saisissez votre adresse mail",
    "reset_pwd_err_empty": "Vous devez saisir un mot de passe",
    "reset_pwd_err_confirm": "Vous devez saisir deux fois le même mot de passe",
    "reset_pwd_err_invalid": "Votre mot de passe est invalide",
    "reset_email_err_empty": "Vous devez saisir une adresse mail",
    "reset_email_err_confirm": "Vous devez saisir deux fois la même adresse mail",
    "reset_email_err_invalid": "Votre  est invalide",
    "reset_pwd_perform": "Changer le mot de passe",
    "reset_email_perform": "Changer l'adresse mail",
    "changepwd_title": "Changement de mot de passe",
    "changepwd_success": "Changement de mot de passe effectué avec succès",
    "changeemail_success": "Changement d'adresse mail effectué avec succès",
    "file_confirm_delete": "Êtes-vous sûr de vouloir supprimer le fichier {item} ?",
    "badpwd_previous": "Vérifiez votre mot de passe actuel",
    "badpwd_empty": "Le mot de passe n'est pas renseigné",
    "bademail_empty": "L'email n'est pas renseigné",
    "list_empty": "Aucune donnée à afficher",
    "search_here": "Rechercher ici",
    "classic_daily_overview_label": "Quotidiennes Classiques",
    "modernized_daily_overview_label": "Quotidiennes Modernisées",
    "weekly_overview_label": "Hebdomadaires",
    "vh_reviews": "Bilans VH",
    "circuit_review": "Bilan des circuits",
    "healthcare_review": "Organisation des conditions de travail des personnels",
    "crisis_review": "Périodes de gestion de crise",
    "formation_review": "Bilan des formations",
    "materiel_review": "Bilan du matériel",
    "ager_label": "AGER",
    "uer_label": "UER",
    "cei_label": "CEI",
    "type_vh": "Type VH",
    "circuit_label": "Circuit",
    "circuits_label": "Circuits",
    "sections": "Sections",
    "axis": "Axes",
    "users": "Utilisateurs",
    "user_groups": "Groupes utilisateurs",
    "group": "Groupe",
    "year": "Année",
    "current_year": "Année courante",
    "date": "Date",
    "date_after": "Jusqu'au",
    "period_day": "Journée du {0}",
    "period_night": "Nuit du {0} au {1}",
    "day_night": "Moment de la mission",
    "type_vh_label": "Type de VH",
    "type_vh_display_label": "Afficher les circuits par type VH",
    "administration": "Administration",
    "ceiAgentManagment": "Gestion des agents",
    "comment": "Commentaire",
    "see_all": "Tout voir",
    "see_mine": "Voir les miens",
    "required_fields_empty": "Certains champs obligatoires sont vides (surlignés en rouge)",
    "fields_size_error": "Certains champs sont trop longs (surlignés en rouge)",
    "settings_saved": "Paramètres enregistrés",
    "settings_not_saved": "Paramètres non enregistrés",
    "agent": "Agent",
    "author": "Auteur",
    "reset_filter": "Réinitialiser les filtres",
    "filter_reset_success": "Filtres réinitialisés",
    "date_range": "Période",
    "impossible_to_update_archived_sheet": "Impossible de modifier une fiche archivée",
    "add_affectation_dialog": "Ajouter une affectation",
    "import_key": "Clé d'import",
    "alerts": "Alertes",
    "user": {
        "create_dialog": "Création d'un utilisateur",
        "update_dialog": "Modification de l'utilisateur",
        "delete_dialog": "Suppression de l'utilisateur",
        "import_dialog": "Import des utilisateurs",
        "no_import_file_selected": "Aucun fichier sélectionné pour l'import",
        "import_success": "Import réussi",
        "import_error": "Erreur lors de l'import",
        "import_error_file_format": "Erreur lors de l'import. Vérifiez le format du fichier importé",
        "import_error_empty_file": "Erreur lors de l'import. Le fichier est vide",
        "confirm_delete": "Êtes-vous sûr de vouloir supprimer l'utilisateur",
        "list": "Liste des utilisateurs",
        "details": "Fiche utilisateur",
        "one": "Utilisateur",
        "empty": "Aucun utilisateur",
        "name": "Nom",
        "first_name": "Prénom",
        "last_name": "Nom",
        "password": "Mot de passe",
        "password_edition": "Mot de passe (inchangé par défaut)",
        "password_new": "Nouveau mot de passe",
        "password_previous": "Mot de passe actuel",
        "password_repeat": "Confirmation du mot de passe",
        "email": "E-mail",
        "emailLogin": "E-mail (Login)",
        "tel": "Téléphone",
        "roleKey": "Rôle",
        "enabled": "Actif",
        "access": "Accès propriétaire",
        "add": "Ajouter un utilisateur",
        "added": "Utilisateur ajouté",
        "update": "Modifier un utilisateur",
        "updated": "Utilisateur modifié",
        "deleted": "Utilisateur supprimé",
        "my_profile": "Mon profil",
        "hubspot": "Hubspot",
        "ug_label": "Groupe d'utilisateurs",
        "exportList": "Exporter la liste",
        "importList": "Importer la liste",
        "affectations_not_valid": "La configuration minimale de l'affectation pour les groupes de cet utilisateur n'est pas respectée.",
        "mandatory_field_depends_on_group": "Les champs obligatoires dépendent du/des groupe(s) de l'utilisateur",
    },
    "ager": {
        "label": "Libellé",
        "list": "Liste des AGER",
        "added": "L'AGER a été ajouté",
        "updated": "L'AGER a été modifié",
        "deleted": "L'AGER a été supprimé",
        "create_dialog": "Création d'un AGER",
        "edit_dialog": "Édition de l'AGER",
        "delete_dialog": "Suppression de l'AGER",
        "confirm_delete": "Êtes-vous sûr de vouloir supprimer l'AGER ?",
    },
    "uer": {
        "label": "Libellé",
        "list": "Liste des UER",
        "added": "L'UER a été ajouté",
        "updated": "L'UER a été modifié",
        "deleted": "L'UER a été supprimé",
        "create_dialog": "Création d'un UER",
        "edit_dialog": "Édition de l'UER",
        "delete_dialog": "Suppression de l'UER",
        "confirm_delete": "Êtes-vous sûr de vouloir supprimer l'UER ?",
    },
    "cei": {
        "label": "Libellé",
        "list": "Liste des CEI",
        "added": "Le CEI a été ajouté",
        "updated": "Le CEI a été modifié",
        "deleted": "Le CEI a été supprimé",
        "create_dialog": "Création d'un CEI",
        "edit_dialog": "Édition du CEI",
        "delete_dialog": "Suppression du CEI",
        "confirm_delete": "Êtes-vous sûr de vouloir supprimer le CEI ?",
    },
    "circuit": {
        "label": "Libellé",
        "axis": "Axe",
        "number": "Numéro de circuit",
        "section": "Section",
        "list": "Liste des circuits",
        "added": "Le circuit a été ajouté",
        "updated": "Le circuit a été modifié",
        "deleted": "Le circuit a été supprimé",
        "create_dialog": "Création d'un circuit",
        "edit_dialog": "Édition du circuit",
        "delete_dialog": "Suppression du circuit",
        "confirm_delete": "Êtes-vous sûr de vouloir supprimer le circuit ?",
        "delete_selected_circuits": "Supprimer les circuits sélectionnés",
        "delete_selected_dialog": "Suppression des circuits",
        "no_circuit_selected": "Aucun circuit sélectionné",
        "too_many_circuits_selected": "Vous ne pouvez pas supprimer plus de 50 circuits à la fois",
        "circuits_deleted": "Les circuits sélectionnés ont été supprimés",
        "circuits_not_deleted": "Les circuits n'ont pas pu être supprimés",
        "confirm_delete_selected": "Êtes-vous sûr de vouloir supprimer les circuits sélectionnés ?",
        "deleted_failed": "Le circuit n'a pas pu être supprimé",
        "download_excel": "Télécharger",
    },
    "data_show_type" : {
        "show_choice": "Type de données",
        "all": "Tout montrer",
        "redacting": "En rédaction",
        "archived": "Archivées",
        "validated": "Validées",
        "per_day": "Par jour",
    },
    "forecast_sheet": {
        "title": "Prévisions",
        "update_this": "Modifier",
        "hours": "Heures",
        "created_by": "Créée par",
        "updated_by": "Mise à jour par",
        "validated_by": "Validée par",
        "list": "Fiches de prévisions",
        "details": "Détails de la fiche de prévision",
        "patrol_start": "Heure de début de patrouille",
        "precurative_start": "Heure de début de précuratif",
        "curative_start": "Heure de début de curatif",
        "intervention_observation": "Précisions complémentaires",
        "forecast_observation": "Observations (ex: état de chaussée, prévision de couverture nuageuse, refroidissement, prévision de neige...)",
        "weekend_activation": "Prévision d'activation pour le week-end",
        "weather_forecast": "Prévisions météorologiques",
        "files": "Fichiers",
        "additional_files": "Fichiers supplémentaires",
        "intervention_forecast": "Prévisions d'interventions",
        "creator_name": "Nom du créateur de la fiche",
        "creator_tel": "Téléphone du créateur de la fiche",
        "forecast_per_hour": "Prévisions par heures",
        "history": "Historique",
        "status": "Statut",
        "comment": "Commentaire",
        "comments": "Commentaires",
        "weekend": "Activation pour le week-end",
        "weekend_motif": "Motif",
        "validation_comment": "Commentaire de validation",
        
        "create": "Création d'une fiche de prévision",
        "update": "Édition de la fiche de prévision",
        "add_comment": "Commenter",
        "download": "Télécharger",
        "download_failed": "Erreur lors du téléchargement",
        "added": "La fiche de prévision a été enregistrée comme brouillon",
        "added_validated": "La fiche de prévision a été validée et envoyée",
        "updated": "La fiche de prévision a été enregistrée comme brouillon",
        "updated_validated": "La fiche de prévision a été validée et envoyée",
        "deleted": "La fiche de prévision a été supprimée",
        "deleted_failed": "La fiche de prévision ne peut être supprimée",
        "forecast_sheets_deleted": "Les fiches de prévision auxquelles vous avez accès ont été supprimées",
        "forecast_sheets_not_deleted": "Les fiches de prévision n'ont pas été supprimées",
        "delete_selected_forecast_sheets": "Supprimer les fiches de prévision sélectionnées",
        "no_forecast_sheet_selected": "Aucune fiche de prévision sélectionnée",
        "too_many_forecast_sheets_selected": "Vous ne pouvez pas supprimer plus de 50 fiches de prévision à la fois",
        "validated": "La fiche de prévision a été validée",
        "commented": "Commentaire ajouté",
        
        "delete_dialog": "Suppression de la fiche de prévision",
        "validate_dialog": "Validation de la fiche de prévision",
        "comment_dialog": "Commentaire pour la fiche de prévision",
        
        "confirm_delete": "Êtes-vous sûr de vouloir supprimer la fiche de prévision ?",
        "confirm_validation": "Êtes-vous sûr de vouloir valider la fiche de prévision ? Vous ne pourrez plus modifier les informations saisies.",
        "confirm_temp_validation": "Êtes-vous sûr de vouloir valider la fiche de prévision ?",
        "no_fiche": "N° de fiche",
        "error": {
            "create": "Erreur lors de la création de la fiche de prévision. Veuillez vérifier le formulaire.",
            "update": "Erreur lors de la modification de la fiche de prévision. Veuillez vérifier le formulaire.",
        } 
    },
    "forecast_row": {
        "hour": "Heure",
        "ground_temp": "Température du sol (°C)",
        "air_temp": "Température de l'air (°C)",
        "humidity": "Humidité relative (%)",
        "ground_temp_short": "Sol (°C)",
        "air_temp_short": "Air (°C)",
        "humidity_short": "Hr (%)",
        "list": "Enregistrements",
        "details": "Détails de l'enregistrement",
        "add": "Ajout d'un enregistrement",
        "add_row": "Ajouter une prévision",
        "delete_dialog": "Suppression de la prévision",
        "confirm_delete": "Êtes-vous sûr de vouloir supprimer la prévision ?",
    },
    "link_sheet": {
        "title": "Liaisons",
        "created_by": "Créée par",
        "updated_by": "Mise à jour par",
        "validated_by": "Validée par",
        "circuits": "Circuits",
        "list": "Fiches de liaisons",
        "details": "Détails de la fiche de liaison",
        "update_this": "Modifier",
        "road_weather": "Météorologie routière",
        "treatment_strategy": "Stratégie de traitement",
        "traffic_conditions": "Conditions de conduite hivernale", 
        "freezing_humidity": "Congélation d'humidité préexistante",
        "ground_frost": "Gelée blanche",
        "strategie_traitement": "Stratégie de traitement",
        "rain_on_frozen_ground": "Pluie sur sol gelé",
        "rain_on_surfusion": "Pluie en surfusion",
        "freezing_fog": "Brouillard givrant",
        "ground_temp": "Température du sol (°C)",
        "air_temp": "Température de l'air (°C)",
        "relative_humidity": "Humidité relative (%)",
        "observations": "Observations",
        "alert_hour": "Heure d'alerte",
        "start_hour": "Heure de début",
        "end_hour": "Heure de fin",
        "dry_salt_tonnage": "Tonnage de sel sec",
        "brine_liters": "Litres de saumures",
        "agent_number": "Nombre d'agent dans l'équipe d'intervention",
        "patrol": "Patrouille",
        "precurative": "Précuratif",
        "curative": "Curatif",
        "scraping": "Raclage",
        "alertes": "Alertes",
        "singular_points": "Points singuliers",
        "history": "Historique",
        "download": "Télécharger",
        "status": "Statut",
        "type": "Type de fiche",
        
        "add": "Création de fiche de liaison",
        "update": "Modification de fiche de liaison",
        
        "added": "La fiche de liaison a été enregistrée comme brouillon",
        "added_validated": "La fiche de liaison a été validée et envoyée",
        "updated": "La fiche de liaison a été modifiée",
        "updated_validated": "La fiche de liaison a été modifiée et validée",
        "deleted": "La fiche de liaison a été supprimée",
        "deleted_failed": "La fiche de liaison ne peut être supprimée",
        "link_sheets_deleted": "Les fiches de liaison auxquelles vous avez accès ont été supprimées",
        "link_sheets_not_deleted": "Les fiches de liaison n'ont pas été supprimées",
        "delete_selected_link_sheets": "Supprimer les fiches de liaison sélectionnées",
        "no_link_sheet_selected": "Aucune fiche de liaison sélectionnée",
        "too_many_link_sheets_selected": "Vous ne pouvez pas supprimer plus de 50 fiches de liaison à la fois",
        "validated": "La fiche de liaison a été validée",
        
        "delete_dialog": "Suppression de la fiche de liaison",
        "validate_dialog": "Validation de la fiche de liaison",
        
        "confirm_delete": "Êtes-vous sûr de vouloir supprimer la fiche de liaison ?",
        "confirm_validation": "Êtes-vous sûr de vouloir valider la fiche de liaison ? Vous ne pourrez plus modifier les informations saisies.",
        "confirm_validation_alerte": "Vous avez renseigné une température du sol > 0°C et indiqué une stratégie de traitement par du sel. Êtes-vous sûr de vouloir valider la fiche de liaison ? Vous ne pourrez plus modifier les informations saisies.",
        "no_fiche": "N° de fiche",
        "error": {
            "create": "Erreur lors de la création de la fiche de liaison. Veuillez vérifier le formulaire.",
            "update": "Erreur lors de la modification de la fiche de liaison. Veuillez vérifier le formulaire.",
        },
        "alerts": {
            "title": "Alertes",
            "see_all": "Tout voir",
            "true": "Alerte",
            "false": "Pas d'alerte",
        }
    },
    "link_row": {
        "roadway_state": "État de la chaussée",
        "snow_state": "État de la neige",
        "traffic_condition": "Conditions de conduite hivernale",
        "list": "Enregistrements",
        "details": "Détails de l'enregistrement",
        "add": "Ajout d'un enregistrement",
        "update": "Modification de l'enregistrement",
        "added": "L'enregistrement a été ajouté",
        "updated": "L'enregistrement a été modifié",
        "deleted": "L'enregistrement' a été supprimé",
        "delete_dialog": "Suppression de l'enregistrement",
        "confirm_delete": "Êtes-vous sûr de vouloir supprimer l'enregistrement ?",
    },
    "vh_type": {
        "highway": "VH Autoroutes",
        "national": "VH Nationales",
    },
    "link_night": {
        "day": "Journée",
        "night": "Nuit",
    },
    "link_type": {
        "start": "Début d'intervention",
        "intermediary": "Milieu d'intervention",
        "end": "Fin d'intervention",
    },
    "traffic_condition": {
        "C1": "C1",
        "C2": "C2",
        "C3": "C3",
        "C4": "C4",
    },
    "roadway_state": {
        "dry": "Sèche",
        "humid": "Humide",
        "wet": "Mouillée ",
        "dripping": "Ruisselante",
    },
    "snow_state": {
        "dry": "Sèche",
        "humid": "Humide",
        "wet": "Mouillée ",
    },
    "ager_role": {
        "direction": "Direction AGER",
        "cadre": "Cadre N1-AGER",
        "ugf": "UGF AGER",
        "bagr": "BAGR AGER",
    }, 
    "cei_role": {
        "direction": "Direction CEI",
        "ri": "R.I CEI",
    },
    "user_group": {
        "list": "Liste des groupes utilisateurs",
        "name": "Nom",
        "added": "Le groupe utilisateur a été ajouté",
        "updated": "Le groupe utilisateur a été modifié",
        "deleted": "Le groupe utilisateur a été supprimé",
        "create_dialog": "Création d'un groupe utilisateur",
        "edit_dialog": "Édition du groupe utilisateur",
        "delete_dialog": "Suppression du groupe utilisateur",
        "confirm_delete": "Êtes-vous sûr de vouloir supprimer le groupe utilisateur ?",
    },
    "classic_daily_summary": {
        "list": "Synthèses quotidiennes classiques",
        "title": "Synthèse quotidienne classique du {0}",
        "virtual_cei": "Afficher les CEI virtuels",
        "intervention_table": "Tableau de synthèse des interventions sur le réseau DiRIF",
        "localisation": "Localisation",
        "forecast": "Prévision",
        "link": "Liaison(s)",
        "intervention_types": "Types d'intervention",
        "intervention_features": "Caractéristiques d'intervention",
        "winter_driving_conditions": "Conditions de conduite hivernales",
        "forecast_sheet": "Fiche de prévision",
        "precurative_treatment": "Traitement préventif / pré-curatif",
        "curative_treatment": "Traitement curatif",
        "sore_points": "Points sensibles",
        "link_sheet_begin": "Fiche de liaison (début d'intervention)",
        "link_sheet_end": "Fiche de liaison (fin d'intervention)",
        "link_sheet_inter": "Fiche de liaison (intermédiaire)",
        "patrolling": "Patrouillage",
        "intervention_begin_intervention": "heure de début d’intevention",
        "intervention_end_intervention": "heure de fin d’intevention",
        "dry_salt": "Sel sec utilisé (T)",
        "brine": "Saumure utilisée (L)",
    },
    "modernized_daily_summary": {
        "list": "Synthèses quotidiennes modernisées",
        "title": "Synthèse quotidienne modernisée du {0}",
        "virtual_cei": "Afficher les CEI virtuels",
    },
    "weekly_summary": {
        "list": "Synthèses hebdomadaires",
        "title": "Synthèse hebdomadaire du {0} au {1} (semaine {2})",
        "virtual_cei": "Afficher les CEI virtuels",
    },
    "sheet": {
        "created_by": "Fiche créée par {0}",
        "validated_by": "Fiche validée par {0}",
    },
    "validation_status": {
        "validated": "Validée",
        "redaction": "En rédaction",
        "archived": "Archivée"
    },
    "bilan_cond_travail": {
        "list": "Bilan de l’organisation des conditions de travail des personnels",
        "synthese": "Bilan de l’organisation des conditions de travail des personnels",
        "week": "N° de semaine",
        "week_start": "N° de semaine (début)",
        "week_end": "N° de semaine (fin)",
        "title1": "Retour d'astreinte",
        "title2": "Dérogations aux garanties minimales",
        "title3": "Organisations spécifiques pour gérer les événements importants attendus",
        "taux_retour_astreinte": "Taux de retour sur astreinte (VH+accident)",
        "nb1": "Nombre de cas individuels où la durée de travail hebdomadaire a été supérieure à 60 h",
        "nb2": "Nombre de cas où pour une période de 12 semaines consécutives, la durée du travail hebdomadaire a été supérieure à 44 h en moyenne",
        "nb3": "Nombre de cas individuels de repos quotidien inférieur à 9 h",
        "nb4": "Nombre de cas individuels de repos quotidien inférieur à 7 h",
        "nb5": "Nombre de cas individuels de travail quotidien supérieur à 12 h",
        "nb6": "Nombre de cas individuels de travail quotidien supérieur à 15 h",
        "nb7": "Nombre de cas où le repos récupérateur de 11 h a été interrompu",
        "nb8": "Nombre de cas où le repos récupérateur de 35 h a été interrompu",
        "nb9": "Nombre de mises en repos récupérateur (11 et 35h)",
        "nb10": "Nombre d'occurrences (semaine-agent) de périodes de travail dépassant 7 jours de travail consécutifs",
        "nb11": "Nombre d'occurrences de mises en astreinte avec un préavis inférieur à 15 jours",
        "meo_action_renforcee": "Mise en œuvre de l'action renforcée",
        "meo_org_travail_spec": "Mise en œuvre d'organisations de travail spécifiques",
        "mep_prog_activite": "Mise en place d'une programmation de l'activité permettant un repos de 9h avant un événement significatif attendu la nuit suivante",
        "org_particuliere_av_we": "Organisation particulière avant un WE avec un événement significatif attendu Autres organisations mises en place",
    },
    "bilan_crise": {
        "list": "Bilan des périodes de gestion de crise",
        "synthese": "Bilan des périodes de gestion de crise",
        "name": "Nom",
        "input_text_area_title": "Problème et solution",
        "date_start": "Date de début",
        "date_end": "Date de fin",
        "period": "Période de crise",
        "add_crise": "Ajouter une crise",
        "add_crise_period": "Ajouter une période de crise",
        "issue": "Problème rencontré",
        "solution": "Solution trouvée",
        "create": "Création d'une crise",
        "period_create": "Création d'une période de crise",
        "created": "La crise a été créée",
        "period_created": "La période de crise a été créée",
        "details": "Détails de la crise",
        "period_details": "Détails de la période de crise",
        "update": "Modification de la crise",
        "period_update": "Modification de la période de crise",
        "updated": "La crise a été modifiée",
        "period_updated": "La période de crise a été modifiée",
        "deleted": "La crise a été supprimée",
        "period_deleted": "La période de crise a été supprimée",
        "period_delete_error": "Une période de crise ne peut être supprimée que si il n'y a aucune crise liée à celle-ci",
        "period_title": "Période du {0} au {1}",
        "problem_not_found": "Problème non renseigné",
        "solution_not_found": "Solution non renseignée",
        "problem_description": "Problème : ",
        "solution_description": "Solution : ",
        "delete_crisis_dialog": "Suppression de la crise",
        "confirm_delete_crisis": "Êtes-vous sûr de vouloir supprimer cette crise ?",
        "delete_period_dialog": "Suppression de la période de crise",
        "confirm_delete_period": "Êtes-vous sûr de vouloir supprimer cette période de crise ?",
    },
    "bilan_formation": {
        "title1": "GPS",
        "title2": "PEVH",
        "title3": "Formation des agents au matériel VH",
        "title4": "Formation des agents aux circuits VH",
        "title5": "Formation RIVH",
        "list": "Bilan des formations",
        "synthese": "Bilan des formations",
        "gps_diff": "Mode de diffusion de la note sur les GPS",
        "gps_form": "Mode de formation des agents au GPS",
        "pevh_date_parution": "Date de parution",
        "pevh_date_validation": "Date de validation",
        "pevh_mode_diffusion": "Mode de diffusion",
        "materiel_forme": "Forme de la formation",
        "materiel_commentaire": "Commentaires",
        "circuit_forme": "Forme de la formation",
        "circuit_commentaire": "Commentaires",
        "rivh_nb": "Nombre d’agents formés",
        "rivh_commentaire": "Commentaires",
    },
    "bilan_circuit": {
        "list": "Bilan des circuits",
        "synthese": "Bilan des circuits",
        "added": "Le bilan a été ajouté",
        "updated": "Le bilan a été modifié",
        "deleted": "Le bilan a été supprimé",
        "week": "N° de semaine",
        "circuit": "Circuit",
        "sections": "Sections",
        "axes": "Axes",
        "section": "Section",
        "axe": "Axe",
        "nb_conduite": "Conduite à 1 ou conduite à 2",
        "lineaire_total": "Linéaire total du circuit (m)",
        "lineaire_salage": "Linéaire salage (m)",
        "surface_salage": "Surface salage (m²)",
        "duree_estime_traitement": "Durée estimée du traitement en heures",
        "duree_estime_parcours": "Durée estimée du parcours en heures",
        "tonnage_estime_sel": "Tonnage estimé de sel utilisé en précuratif à 15 g",
        "capacite_saleuse": "Capacité saleuse utilisée (m³)",
        "nb_inter_precur": "Nombre total d'interventions précuratives",
        "nb_inter_cur": "Nombre total d'interventions curatives",
        "nb_inter_pts_sensible": "Nombre total d'interventions points sensibles",
        "ton_dry_salt_used": "Sel sec utilisé (T)",
        "litre_brine_used": "Saumure utilisée (L)",
        "nb_circuits": "Nombre de circuits",
    },
    "bilan_materiel": {
        "list": "Bilan des matériels",
        "synthese": "Bilan des matériels",
        "added": "Le bilan a été ajouté",
        "updated": "Le bilan a été modifié",
        "deleted": "Le bilan a été supprimé",
        "week": "N° de semaine",
        "circuit": "Circuits (ou numéro de mulet)",
        "sections": "Sections",
        "axes": "Axes",
        "section": "Section",
        "axe": "Axe",
        "truck_type": "Type du camion (16 T, 19 T, 26 T)",
        "brand": "Marque",
        "rent": "Location",
        "salt_unit_brine": "Unités de salage (avec et sans saumure)",
        "salt_unit": "Unités de salage",
        "brine": "Saumure",
        "with": "Avec",
        "without": "Sans",
        "nb_repair_materiel_vh": "Nombre de réparations effectuées sur matériel VH",
        "problem_met_and_suggestions": "Problèmes spécifiques rencontrés et suggestions",
        "v3m3": "3 m³",
        "v4a45m3": "4 et 4,5 m³",
        "v5m3": "5 m³",
        "v6m3": "6 m³",
        "v7a8m3": "7 et 8 m³",
        "nb_circuits": "Nombre de circuits",
        "mule": "Mulet",
        "ager_mule": "Mulets de l'AGER {0}",
        "new_mule": "Nouveau mulet",
        "mule_number": "Numéro de mulet",
        "total": "Total AGER {0}",
        "truck_info": "Caractéristiques du camion",
        "salting": "Unités de salage",
        "repairs_and_problems": "Remarques"
    },
    "bilan": {
        "author": "Auteur",
        "delete_dialog": "Suppression du bilan",
        "confirm_delete": "Êtes-vous sûr de vouloir supprimer ce bilan ?",
        "details": "Détail d'un bilan",
        "create": "Création d'un bilan",
        "update": "Modification d'un bilan",
        "added": "Le bilan a été ajouté",
        "updated": "Le bilan a été modifié",
        "deleted": "Le bilan a été supprimé",
        "error": {
            "create": "Erreur lors de la création du bilan. Veuillez vérifier le formulaire.",
            "update": "Erreur lors de la modification du bilan. Veuillez vérifier le formulaire.",
            "weeks": "Les numéros de semaine sont incorrects",
            "dates": "Les dates sont incorrectes",
            "no_circuits_cei": "Aucun circuit n'a été renseigné pour ce CEI",
        },
    },
    "ceiAgent" : {
        "list" : "Liste des agents",
        "update_dialog" : "Modification d'un agent",
        "create_dialog" : "Création d'un agent",
        "delete_dialog" : "Suppression d'un agent",
        "confirm_delete": "Êtes-vous sûr de vouloir supprimer l'agent",
    },
    "form": {
        "row_copied": "Données copiées",
        "row_pasted": "Données collées",
        "no_row_copied": "Veuillez d'abord copier des données",
    },
    "axeintervention": {
        "list": "Liste des axes d'intervention",
        "added": "L'axe d'intervention a été ajouté",
        "updated": "L'axe d'intervention a été modifié",
        "deleted": "L'axe d'intervention a été supprimé",
        "libelle": "Libellé",
        "create_dialog": "Création d'un axe d'intervention",
        "edit_dialog": "Modification d'un axe d'intervention",
        "name": "Axe d'intervention",
        "names": "Axes d'interventions",
        "delete_dialog": "Suppression d'un axe d'intervention",
        "confirm_delete": "Êtes-vous sûr de vouloir supprimer cet axe d'intervention ?",
        "autoroute": "Autoroute",
    },
    "intervention": {
        "created": "L'intervention a été créée",
        "no_intervention_cei": "N° Inter CEI",
        "title": "Fiches des interventions",
        "add_intervention": "Ajouter une intervention",
        "autre_agent": "Autre agent",
        "details": "Détails d'une intervention",
        "update": "Modification d'une intervention",
        "deleted": "L'intervention a été supprimée",
        "deleted_failed": "Erreur lors de la suppression de l'intervention",
        "create": "Création d'une intervention",
        "ager": "AGER",
        "cei": "CEI",
        "call_date_hour": "Date/Heure d'appel",
        "call_date": "Date",
        "call_hour": "Heure",
        "call_origin": "Origine de l'appel",
        "other_call_origin": "Autre origine de l'appel",
        "axe_sens": "Axe/Sens",
        "ri": "R.I.",
        "axe": "Axe",
        "sens": "Sens",
        "voie": "Voie",
        "pr_voie": "PR/Voie",
        "pr": "PR",
        "agents": "Agents",
        "update_observation": "Modification de l'observation",
        "coordonnees": "Créateur de la fiche",
        "author": "Auteur",
        "error_heure_debut_fin": "L'heure de fin doit être supérieure à l'heure de début",
        "error_heure_appel_fin": "L'heure de fin doit être supérieure à l'heure d'appel",
        "error_heure_arrivee_depart": "L'heure de départ doit être supérieure à l'heure d'arrivée",
        "error_heure_debut_arrivee": "L'heure d'arrivée doit être supérieure à l'heure de début",
        "error_heure_depart_fin": "L'heure de fin doit être supérieure à l'heure de départ",
        "commune": "Commune",
        "horaire_arrivee_site": "Horaire d'arrivée sur le lieu d'intervention",
        "horaire_depart_site": "Horaire de départ du lieu d'intervention",
        "heure_debut_fin": "Heure de début/fin",
        "heure_debut": "Heure de début",
        "heure_fin": "Heure de fin",
        "heure_arrivee_depart": "Heure d'arrivée/départ du lieu d'intervention",
        "heure_arrivee": "Arrivée",
        "heure_depart": "Départ",
        "observation": "Observations",
        "presence_chef_equipe": "Présence du chef d'équipe",
        "presence_ri": "Présence du RI",
        "lieu_intervention": "Lieu d'intervention",
        "environnement": "Environnement",
        "type_intervention": "Type d'intervention",
        "other_type_intervention": "Autre type d'intervention",
        "precision_niveau_1": "Précision niveau 1",
        "precision_niveau_2": "Précision niveau 2",
        "intervention": "Intervention",
        "moyens_utilises": "Moyens utilisés pour l'intervention",
        "moyens_humains": "Moyens humains",
        "moyens_materiels": "Moyens matériels",
        "etat_chaussee": "Etat de la chaussée",
        "conditions_meteo": "Conditions météorologiques",
        "vehicules_impliques": "Véhicules impliqués",
        "save_intervention": "Enregistrer l'intervention",
        "circonstances_connues": "Circonstances connues",
        "edit_dialog": "Modification d'une intervention",
        "confirm_update": "Êtes-vous sûr de vouloir modifier cette intervention ?",
        "confirm_create": "Êtes-vous sûr de vouloir créer cette intervention ?",
        "confirm_delete": "Êtes-vous sûr de vouloir supprimer cette intervention ?",
        "files": "Fichiers",
        "update_this": "Modifier l'intervention",
        "updated": "L'intervention a été modifiée",
        "display_user_intervention": "Afficher les interventions de l'utilisateur",
        "display_all_intervention": "Afficher toutes les interventions",
        "nb_item_prefix_label" : "Nombre de",
        "pr_format_tooltip" : "Chiffres et point autorisés uniquement (Exemple : 10.600)",
        "delete_dialog": "Suppression d'une intervention",
        "delete_selected_interventions": "Supprimer les interventions sélectionnées",
        "interventions_deleted": "Les interventions ont été supprimées",
        "interventions_not_deleted": "Erreur lors de la suppression des interventions",
        "no_intervention_selected": "Aucune intervention sélectionnée",
        "settings_dialog": "Paramètres d'exports des interventions",
        "table_settings_dialog": "Paramètres d'affichage de la liste d'interventions",
        "update_settings": "Vous allez changer vos paramètres d'exports des interventions",
        "update_table_settings": "Vous allez changer vos paramètres d'affichage de la liste d'interventions",
        "noBretelle": "N° Bretelle",
        "noAutoroute": "N° Autoroute",
    },
    "intervention_list" : {
        "download_excel_full": "Télécharger fiches complètes",
        "download_excel" : "Télécharger fiches simplifiées",
        "download_custom_excel": "Télécharger fiches personnalisées",
        "settings_custom_excel": "Personnaliser export fiches",
        "reset_custom_filter": "Réinitialiser",
        "add_full_intervention" : "Ajouter Fiche complète intervention",
        "add_light_intervention" : "Ajouter Fiche simplifiée",
        "settings_intervention_table": "Paramètres d'affichage de la liste d'interventions",
        "tick_all": "Tout cocher",
        "untick_all": "Tout décocher",
    },
    "sens": {
        "w": "W",
        "y": "Y",
        "int": "INT",
        "ext": "EXT",
    },
    "voie": {
        "bau": "BAU",
        "voie_lente": "Voie lente",
        "voie_rapide": "Voie rapide",
        "voie_mediane": "Voie(s) médiane(s)",
        "tpc": "TPC",
        "accotement": "Accotement",
    },
    "origine_appel": {
        "ost": "OST",
        "police": "Police",
        "gendarmerie": "Gendarmerie",
        "pompiers": "Pompiers",
        "depanneurs": "Dépanneurs",
        "autres_gestionnaires_reseau": "Autres gestionnaires de réseau",
        "usagers": "Usagers",
        "ri": "RI",
        "autre": "Autre",
    },
    "type_intervention": {
        "accident": "Accident",
        "animaux": "Animaux",
        "bouchon": "Bouchon",
        "intemperie": "Intempérie",
        "obstacle_mobile_fixe": "Obstacle mobile/fixe",
        "vehicule_en_panne": "Véhicule en panne",
        "balisage": "Balisage",
        "chaussee": "Chaussée",
        "autre": "Autre",
    },
    "moyens_humains": {
        "chef_equipe": "RI",
        "agent_exploitation": "Agent d'exploitation",
    },
    "moyens_materiels": {
      "vl": "VL",
      "vul": "VUL",
      "fourgons": "Fourgons",
      "trafic_partner": "Trafic/Partner",
      "flr": "FLR",
      "sac_seau_absorbant": "Sac ou seau d'absorbant",
    },
    "etat_chaussee": {
        "seche": "Sèche",
        "humide": "Humide",
        "mouillee": "Mouillée",
        "ruisselante": "Ruisselante",
        "verglacee": "Verglacée",
        "enneigee": "Enneigée",
    },
    "condition_meteo": {
        "ras": "R.A.S",
        "pluie": "Pluie",
        "neige": "Neige",
        "brouillard": "Brouillard",
    },
    "precisions": {
        "1": {
            "accident": {
                "materiel": "Matériel",
                "corporel": "Corporel",
                "mortel": "Mortel",
            },
            "animaux": {
                "animal_errant": "Animal errant",
                "animal_mort": "Animal mort",
            },
            "bouchon": {
                "ralentissement": "Ralentissement",
                "circulation_accordeon": "Circulation en accordéon",
                "circulation_arretee": "Circulation arrêtée",
            },
            "intemperie": {
                "vent_violent": "Vent violent",
                "pluie_violente": "Pluie violente",
                "chutes_de_neige_violentes": "Chutes de neige violentes",
                "inondation": "Inondation",
                "incendie": "Incendie",
            },
            "obstacle_mobile_fixe": {
                "chaussee_glissante_produits_route": "Chaussée glissante et produits sur la route",
                "evenements_inopines": "Événements inopinés",
                "obstacles": "Obstacles",
                "pieton_cycliste": "Piéton/cycliste",
                "vehicule_genant": "Véhicule gênant",
                "vehicule_contresens": "Véhicule en contresens",
                "vehicule_arrete": "Véhicule arrêté",
            },
            "balisage": {
                "introduction_vehicule_balisage": "Introduction d'un véhicule dans le balisage",
                "autres": "Autres",
            },
            "chaussee": {
                "rebouchage_nid_poule": "Rebouchage d'un nid de poule",
                "epandage_absorbant_chaussee": "Épandage d'absorbant sur la chaussée",
                "autres": "Autres",
            },
        },
        "2": {
            "animaux": {
                "sauvage": "Sauvage",
                "domestique": "Domestique",
                "elevage": "Élevage",
            },
            "chaussee_glissante_produits_route": {
                "eau": "Eau",
                "hydrocarbures": "Hydrocarbures",
                "produits_chimiques_toxiques": "Produits chimiques toxiques",
                "graviers": "Graviers",
                "boue": "Boue",
            },
            "evenements_inopines": {
                "inondation": "Inondation",
                "eboulement": "Éboulement",
                "manifestation": "Manifestation",
                "autres": "Autres",
            },
            "obstacles": {
                "objets": "Objets",
                "cones": "Cones",
                "autres": "Autres",
            },
            "pieton_cycliste": {
                "pieton": "Piéton",
                "cycliste": "Cycliste",
                "trotinette": "Trotinette",
                "autres": "Autres",
            },
            "vehicule_genant": {
                "vehicule_en_difficulte": "Véhicule en difficulté",
                "vehicule_non_autorise": "Véhicule non autorisé",
                "autres": "Autres",
            },
            "vehicule_arrete": {
                "vehicule_arrete": "Véhicule arrêté",
                "vehicule_abandonne": "Véhicule abandonné",
                "vehicule_en_feu": "Véhicule en feu",
            }
        }
    },
    "vehicules_impliques": {
        "title": "Véhicules impliqués",
        "genre": "Genre",
        "marque": "Marque",
        "immatriculation": "Immatriculation",
        "degats_materiels": "Dégâts matériels",
        "dommages_corporels": "Dommages corporels",
        "responsable_ddp": "Responsable DDP",
        "ajouter_vehicule": "Ajouter un véhicule",
        "delete_dialog": "Suppression d'un véhicule",
        "confirm_delete": "Êtes-vous sûr de vouloir supprimer ce véhicule ?",
        "vl": "VL",
        "fourgon": "Fourgon",
        "pl": "PL",
        "moto_scooter": "Moto/Scooter",
        "velo": "Vélo",
        "trotinette": "Trotinette",
        "genre_empty_error": "Le genre d'un véhicule est obligatoire",
        "autre_vehicule": "Autre véhicule",
        "type_vehicule_empty_error": "Le type d'un véhicule est obligatoire si le genre est 'Autre véhicule'",
    },
    "ddp": {
        "title": "Dégâts au domaine public",
        "title_short": "DDP",
        "presence_tiers_responsable": "Présence du tiers à l'origine des DDP sur les lieux",
        "nature_degats": "Nature des dégâts",
        "other_nature":"Préciser les dégâts si besoin",
        "unite": "Unité",
        "quantite": "Quantité",
        "ajouter_ddp": "Ajouter un DDP",
        "delete_dialog": "Suppression d'un DDP",
        "observations": "Observations liées au DDP",
        "confirm_delete": "Êtes-vous sûr de vouloir supprimer ce DDP ?",
        "empty_field_error": "Tous les champs d'un DDP sont obligatoires",
    },
    "nature_degats": {
        "glissieres": "Glissières",
        "portique": "Portique",
        "enrobe": "Enrobé",
        "signalisation_verticale": "Signalisation verticale",
        "autres": "Autres",
    },
    "genre_vehicule": {
        "vl": "VL",
        "fourgon": "Fourgon",
        "pl": "PL",
        "moto_scooter": "Moto/Scooter",
        "velo": "Vélo",
        "trotinette": "Trotinette",
        "autre_vehicule": "Autre véhicule",
    },
    "intervenant": {
        "title": "Intervenants",
        "police": "Police",
        "pompiers": "Pompiers",
        "gendarmerie": "Gendarmerie",
        "depanneurs": "Dépanneurs",
        "samu_smur": "SAMU/SMUR",
        "autres": "Autres",
    },
    "action": {
        "title": "Actions réalisées",
        "balisage": "Balisage",
        "neutralisation_voie_lente": "Neutralisation de voie lente",
        "neutralisation_voie_mediane": "Neutralisation de voie médiane",
        "neutralisation_voie_rapide": "Neutralisation de voie rapide",
        "fermeture_axe": "Fermeture d'axe",
        "deviation": "Déviation",
        "nettoyage_balayage_ramassage": "Nettoyage/Balayage/Ramassage",
        "protection": "Protection",
        "fermeture_bretelle": "Fermeture de bretelle",
        "autre": "Autre",
    },
    "userSettings": {
        "interventions": {
            "noInterDirif": "N° Inter DiRIF",
            "noInterCei": "N° Inter CEI",
            "ager": "AGER",
            "cei": "CEI",
            "auteur": "Auteur",
            "callDate": "Date d'appel",
            "callTime": "Heure d'appel",
            "callOrigin": "Origine de l'appel",
            "axe": "Axe",
            "voie": "Voie",
            "sens": "Sens",
            "localisation": "Localisation",
            "heureDebut": "Heure de début",
            "heureFin": "Heure de fin",
            "typeIntervention": "Type d'intervention",
            "precisionNiveau1": "Précision niveau 1",
            "precisionNiveau2": "Précision niveau 2",
            "observation": "Observations",
            "presenceRi": "Présence du RI",
            "ddp": "DDP",
            "commune": "Commune",
            "heureArriveeSite": "Horaire d'arrivée sur le lieu d'intervention",
            "heureDepartSite": "Horaire de départ du lieu d'intervention",
            "moyensHumains": "Moyens humains",
            "moyensMateriels": "Moyens matériels",
            "etatChaussee": "État de la chaussée",
            "conditionsMeteo": "Conditions météorologiques",
            "intervenants": "Intervenants",
            "circonstances": "Circonstances",
            "actionsRealisees": "Actions réalisées",
            "vehiculesImpliques": "Véhicules impliqués",
            "agents": "Agents",
            "presenceTiersResponsable": "Présence du tiers à l'origine des DDP sur les lieux",
            "noBretelle": "N° Bretelle",
            "noAutoroute": "N° Autoroute",
        }
    },
    tableSettings: {
        interventions: {
            localisation: "Localisation",
            callDate: "Date/Heure d'appel",
            callOrigin: "Origine de l'appel",
            axeSensIntervention: "Axe/Sens",
            observation: "Observations",
            ager: "AGER",
            cei: "CEI",
            ri: "RI",
            ddp: "DDP",
            heureArriveeDepart: "Heure d'arrivée/départ",
            typeIntervention: "Type d'intervention",
            author: "Auteur",
            noInterventionCei: "N° Inter CEI",
        }
    }
};